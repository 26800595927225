<template>
  <div>
    <router-view v-if="subPage"></router-view>
    <CRow v-else>
      <CCol>
        <CCard v-if="$store.state.shop.market.id">
          <CCardHeader>
            <img
              v-if="$store.state.shop.market.role.image_rect_cropped"
              v-bind:src="$store.state.shop.market.role.image_rect_cropped"
              @click="openMarket(market)"
              class="img-fluid float-left mx-2"
            />
            <div class="text-right float-right mx-2">
              zona servita: <br />
              <b v-html="$store.state.shop.market.role.desc_zone" /><br />
              tel: <br />
              <b v-html="$store.state.shop.market.role.tel" /><br />
            </div>
            <div v-html="$store.state.shop.market.role.desc_short" />
          </CCardHeader>
          <CCardBody>
            <CTabs :activeTab.sync="activeTab">
              <CTab :title="$t('role.showcase.showcase.title')">
                <ShowcaseList v-if="activeTab == 0" />
              </CTab>
              <CTab :title="$t('role.showcase.prices.title')">
                <ShowcasePrices v-if="activeTab == 1" />
              </CTab>
              <!-- <CTab :title="$t('role.products.avail.title')">
                <ProductsTableAva v-if="activeTab==2"/>
              </CTab>
              <CTab :title="$t('role.products.all.title')">
                <ProductsTableAll v-if="activeTab==3"/>
              </CTab> -->
            </CTabs>
          </CCardBody>
        </CCard>
        <div v-else class="loading text-center">
          <CSpinner color="primary" size="lg" />
        </div>
      </CCol>
    </CRow>
  </div>
</template>

<script>
import Vue from "vue";
import ShowcasePrices from "./ShowcasePrices";
import ShowcaseList from "./ShowcaseList";

import { GetDataManager, ApiCall } from "../../ds/index";
import { Query } from "@syncfusion/ej2-data";

// import ProductsTableAva from "./ProductsTableAva"
// import ProductsTableAll from "./ProductsTableAll"

// import ProductsList from "./ProductsList"

export default Vue.extend({
  name: "Products",
  data() {
    return {
      activeTab: 1,
    };
  },
  components: {
    ShowcasePrices,
    ShowcaseList,
    // ProductsTableAva,
    // ProductsTableAll,
    // ProductsList
  },
  computed: {
    subPage() {
      return this.$route.matched.length > 3;
    },
  },
  created() {
    // Carica un carrello preesistente se presente
    this.LoadMarketData();
    // this.LoadExistingCart();
  },
  methods: {
    LoadMarketData() {
      if (this.$store.state.shop.market.id != this.$route.params.id) {
        let self = this;
        self.$store.commit("shop/setMarket", {});
        console.log("Load market data");
        ApiCall(
          // GetDataManager("role_producer_products", [this.$store.state.role.id]),
          GetDataManager("auth_markets", [], this.$route.params.id),
          new Query(), //.where('market.role.id', 'equal', ),
          function (e) {
            self.$store.commit("shop/setMarket", e.result);
            self.setRouteLabel();
            self.LoadExistingCart();
          }
        );
      } else {
        this.setRouteLabel();
        if (!this.$store.state.shop.market_cart.id) {
          this.LoadExistingCart();
        }
      }
    },
    setRouteLabel() {
      this.$route.meta.label = this.$store.state.shop.market.role.name;
      // Add a temporary query parameter.
      this.$router.replace({ query: { temp: Date.now() } });
      // Remove the temporary query parameter.
      this.$router.replace({ query: { temp: undefined } });
    },
    LoadExistingCart() {
      console.log("Aggiorna carrelli");
      let self = this;
      //recupera tutti i carrelli utente per questo mercato
      ApiCall(
        // GetDataManager("role_producer_products", [this.$store.state.role.id]),
        GetDataManager("cart_usercarts"),
        new Query().where(
          "market.id",
          "equal",
          this.$store.state.shop.market.id
        ),
        function (e) {
          // self.numPages = Math.ceil(e.actual.Count / 12);
          // self.$store.cart.set(e.result);
          // self.$store.dispatch("cart/add", e.result);
          console.log(e.result);
          if (e.result.length > 0) {
            // console.log(e.result[0])
            self.$store.commit("shop/setCart", e.result[0]);
          } else {
            self.$store.commit("shop/setCart", {});
          }
        }
      );
      // //imposta il primo come carrello corrente
      // console.log("Load existing cart")
    },
  },
});
</script>
